




















































import { Component, Prop } from 'vue-property-decorator'
import { PremiumModel } from '../models/PremiumModel'
import { toLocalCurrency } from '@/utils/currencyFormatter'
import Room, { RoomInterface } from './Room.vue'
import PremiumPlacement from './PremiumPlacement.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

import { BreakpointsMixin } from '@/mixins/breakpoints.mixin'

@Component({
  name: 'PremiumRoom',
  components: {
    Swiper,
    SwiperSlide,
    Room,
    PremiumPlacement,
    BaseButton,
    BaseIcon,
    VueCustomScrollbar,
  },
})
export default class PremiumRoom extends BreakpointsMixin {
  @Prop({ required: true })
  room!: PremiumModel

  swiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.PremiumRoom__pagination',
      clickable: true,
    },
  }
  toLocalCurrency = toLocalCurrency

  get sameRoom(): RoomInterface {
    return this.room
  }

  get priceSum(): number {
    return (
      this.room.premium_placements?.reduce(
        (sum, placement) => sum + (placement.price_month || 0),
        0
      ) || 0
    )
  }
}
